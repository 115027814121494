/* eslint-disable @next/next/inline-script-id */
import Script from 'next/script'
import { useAppRouter } from '@legacy/@core/hooks/UseAppRouter'
import { ColorsStyle } from '@legacy/@core/style/base/colors.style'
import { FontsStyle } from '@legacy/@core/style/base/fonts.style'
import { ResetStyle } from '@legacy/@core/style/base/reset.style'
import { SidebarStyle } from '@legacy/@core/style/layout/sidebar.style'
import { AntdStyle } from '@legacy/@core/style/utilities/antd.style'
import { MixinsStyle } from '@legacy/@core/style/utilities/mixins.style'
import { TextStyle } from '@legacy/@core/style/utilities/text.style'
import { VariableStyle } from '@legacy/@core/style/utilities/variable.style'
import { setOn401, setOn403, useRoutingCollector } from '@legacy/@core/utilities/Client'
import Head from 'next/head'
import 'nprogress/nprogress.css'
import React, { FC, useEffect } from 'react'
import { safe_clearPrivateData } from 'datasource/select/utils'
import {
  NEXT_PUBLIC_GOOGLE_API_KEY,
  NEXT_PUBLIC_APP_DOMAIN,
  NEXT_PUBLIC_NO_INDEX,
  GTM_KEY,
  NEXT_PUBLIC_GLEAP_TOKEN
  // NEXT_PUBLIC_ENABLE_LIVE_CHAT
} from 'utilities/env'
// import GleapWidget from 'utilities/GleapWidget'
import CrispChat from '../utilities/CrispChat'
import { LINK_URL_LOGIN } from '../utilities/LinkURL'
import { getClient } from 'utilities/getIsServer'
import { UtilitiesStyle } from 'style/components/utilities.style'
import dynamic from 'next/dynamic'
const GleapWidget = dynamic(() => import('utilities/GleapWidget'), {
  ssr: false
})
const DefaultLayout: FC<{}> = ({ children }) => {
  const router = useAppRouter()
  useEffect(() => {
    setOn401(() => {
      safe_clearPrivateData()
      return router.push(LINK_URL_LOGIN)
    })
    setOn403(() => {
      safe_clearPrivateData()
      return router.push(LINK_URL_LOGIN)
    })
  }, [])
  return (
    <>
      <Head>
        <meta name="robots" content="noindex, nofollow" />
        <script src={`https://maps.googleapis.com/maps/api/js?key=${NEXT_PUBLIC_GOOGLE_API_KEY}&libraries=places`}></script>

        <link rel="apple-touch-icon" href={`${NEXT_PUBLIC_APP_DOMAIN}/img/apple-touch-icon.png`} />
        {NEXT_PUBLIC_NO_INDEX === 'true' && <meta name="robots" content="noindex" />}

        <title>freeC - Website tuyển dụng thông minh</title>
        <link rel="shortcut icon" type="image/png" sizes="32x32" href="/favicon_32x32.png"></link>
        <link rel="shortcut icon" type="image/png" sizes="16x16" href="/favicon_16x16.png"></link>

        <link rel="preconnect" href="https://fonts.gstatic.com"></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap"
          rel="stylesheet"></link>

        {GTM_KEY && (
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${GTM_KEY}');`
            }}
          />
        )}
      </Head>

      {GTM_KEY && (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${GTM_KEY}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}></iframe>
        </noscript>
      )}
      {/* {NEXT_PUBLIC_ENABLE_LIVE_CHAT && getClient() && <>{!window?.$crisp ? <CrispChat /> : null}</>} */}
      {NEXT_PUBLIC_GLEAP_TOKEN && <GleapWidget />}
      <Script async src="https://widget.frill.co/v2/widget.js" strategy="lazyOnload" />
      {children}
    </>
  )
}

export const LayoutProvider: FC<{}> = ({ children }) => {
  const router = useAppRouter()
  useRoutingCollector(router)

  const style = (
    <>
      <style jsx global>
        {AntdStyle}
      </style>
      <style jsx global>
        {VariableStyle}
      </style>
      <style jsx global>
        {ResetStyle}
      </style>
      <style jsx global>
        {TextStyle}
      </style>
      <style jsx global>
        {FontsStyle}
      </style>
      <style jsx global>
        {ColorsStyle}
      </style>
      <style jsx global>
        {MixinsStyle}
      </style>
      <style jsx global>
        {SidebarStyle}
      </style>
      <style jsx global>
        {UtilitiesStyle}
      </style>
      <style jsx global>
        {`
          .ql-align-center {
            justify-content: center;
            display: flex;
          }
          .ql-align-right {
            justify-content: flex-end;
            display: flex;
          }
        `}
      </style>
    </>
  )

  return (
    <>
      {style}
      <DefaultLayout>{children}</DefaultLayout>
    </>
  )
}

export default DefaultLayout
