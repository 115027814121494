import { NEXT_PUBLIC_APP_DOMAIN_SHARE, NEXT_PUBLIC_LINK_JOB_POST_POLICY } from './env'

export const LINK_URL_JOBS = '/jobs'
export const LINK_URL_FORGOT_PASSWORD = '/forgot-password'
export const LINK_URL_UPDATE_PASSWORD = '/update-password'
export const LINK_URL_REGISTER = '/employer/register'
export const LINK_URL_LOGIN = '/login'
export const LINK_URL_ROOT = '/'
export const LINK_URL_BLOG_CATEGORY = 'https://blog.freec.asia/category/nha-tuyen-dung/'
export const LINK_URL_JOB_CATEGORY = (id: number): string => `/jobs?job_category[]=${id}`
export const LINK_URL_DOWNLOAD_APPLE = 'https://apps.apple.com/us/app/freec/id1475879992?l=vi&ls=1'
export const LINK_URL_DOWNLOAD_ANDROID = 'https://play.google.com/store/apps/details?id=com.freec.asia'
export const LINK_URL_TOKEN_NOT_FOUND = '/token-not-found'
export const LINK_URL_VERIFY_MEMBER_UPDATE_PROFILE_INFOR = '/verify-member/update-member-infor'
export const LINK_URL_EMAIL_SENT = '/forgot-password/confirmation'
export const LINK_URL_FORGOT_VERIFY_TOKEN = '/forgot-password/reset-password'
export const LINK_URL_ACCOUNT_COMPANY = '/account/company-account'
export const LINK_URL_ACCOUNT_TEAMS = '/account/teams'
export const LINK_URL_CANDIDATES_CHANGE_PASSWORD = '/account/change-password'
export const LINK_URL_INVITE_MEMBER = '/account/invite-team-members'
export const LINK_URL_MY_ACCOUNT = '/account/your-account'
export const LINK_URL_EMAIL_TEMPLATES = '/account/email-templates'
export const LINK_URL_EMAIL_SETTING = '/account/email-setting'
export const LINK_URL_ABOUT_FREEC = 'https://freecracy.com/'
export const LINK_URL_CANDIDATES_PLATFORM = 'https://freec.asia/'
export const LINK_URL_LINKIN = 'https://www.linkedin.com/company/freecasia/'
export const LINK_URL_FACEBOOK = 'https://www.facebook.com/freecasiacorp/'
export const LINK_URL_YOUTUBE = 'https://www.youtube.com/channel/UCIb1ZELs5mI5I2T5xTIB-zQ'
export const LINK_JOB_POST_POLICY =
  NEXT_PUBLIC_LINK_JOB_POST_POLICY || 'https://support.freec.asia/vi/article/quy-dinh-dang-tin-1kpnrdd/?bust=1621588019101'
export const LINK_URL_INSTAGRAM = 'https://www.instagram.com/freec_vn/'
export const LINK_URL_WAIT_VERIFICATION = '/employer/register/wait-for-verification'
export const LINK_URL_CANDIDATES_PUBLISH_PROFILE = '/profiles/[...slugName]'

export const LINK_URL_CONSULTANT_JOB_LIST = (id?: number | string): string => `/consultants/job-orders/${id}`
export const LINK_KEY_CONSULTANTS_JOB_LIST = `/consultants/job-orders/[ID]`

export const LINK_URL_CONSULTANT_EDIT_JOB = (id?: number | string): string => `${LINK_URL_CONSULTANT_JOB_LIST(id)}/update`
export const LINK_KEY_COMPANY_EDIT_JOB = `${LINK_KEY_CONSULTANTS_JOB_LIST}/update`

export const LINK_URL_CONSULTANT_JOB_PREVIEW = (companyID: number, id: number | string): string => `/consultants/job-orders/${id}/preview`

export const LINK_URL_CONSULTANT_VIEW_APPLICANT = (jobID: number | string, profileId: number | string): string =>
  `/consultants/job-orders/${jobID}?candidate=${profileId}`
export const LINK_KEY_CONSULTANT_JOB_PREVIEW = `/consultants/review-jobs/[id]`
export const LINK_URL_CONSULTANT_CLIENT = (id: number | string): string => `/consultants/clients/${id}`
export const LINK_URL_CONSULTANTS_OFFER = '/consultants/offer'
export const LINK_URL_CONSULTANTS_INTERVIEW = '/consultants/interview'
export const LINK_URL_CONSULTANTS_SOURCE = '/consultants/source'
export const LINK_URL_CONSULTANTS_CANDIDATES = '/consultants/candidates'
export const LINK_URL_CONSULTANTS_TALENT_POOL = '/consultants/talent-pool'

export const LINK_URL_CONSULTANTS_CLIENT = '/consultants/clients'
export const LINK_URL_CONSULTANTS_CLIENT_CREATE = '/consultants/clients/create'
export const LINK_URL_CONSULTANTS_CLIENT_ADD_AS_CLIENT = '/consultants/clients/add-as-client'
export const LINK_URL_CONSULTANTS_JOB_ORDERS = '/consultants/job-orders'
export const LINK_URL_CONSULTANTS_JOB_ORDERS_CREATE = '/consultants/job-orders/create'
export const LINK_URL_CONSULTANTS_JOBS = `/consultants/job-orders`
export const LINK_URL_CONSULTANTS_DASHBOARD = `/consultants/dashboard`
export const LINK_URL_CONSULTANTS_JOBS_EDIT = (jobId: number | string): string => `/consultants/job-orders/${jobId}/update`
export const LINK_KEY_CONSULTANTS_JOBS_EDIT = '/consultants/job-orders/[id]/update'
export const LINK_URL_CONTACT_SUCCESS = '/employer/contact/success'
export const LINK_URL_CONSULTANT_RECREATE_JOBS = (id: number | string): string => `/consultants/job-orders/create?from-job-id=${id}`
export const LINK_URL_CONSULTANT_VIEW_PROFILE = (id: number | string): string => `/consultants/source/${id}/detail`
export const LINK_URL_CONSULTANT_EDIT_PROFILE = (id: number | string): string => `/consultants/source/${id}/update`
export const LINK_URL_CONSULTANT_COMPANY_DIRECTORY = '/consultants/company-directory'
export const LINK_URL_CONSULTANT_IFRAME_WHAT_NEWS = '/consultants/what-news'
export const LINK_URL_CONSULTANT_REPORTS = '/consultants/reports'
export const LINK_URL_CONSULTANT_IFRAME_FEEDBACK = '/consultants/feedback'
export const LINK_URL_CONSULTANT_IFRAME_FAQ = '/consultants/faq'
export const LINK_URL_CONSULTANT_IFRAME_IDEA = ''
export const LINK_URL_CONSULTANT_TRAINING = '/consultants/training'
export const LINK_KEY_CONSULTANT_VIEW_APPLICANT = '/consultants/job-orders/[id]/applicants/[applicantId]'

export const LINK_URL_CONSULTANT_PLACEMENT = '/consultants/placement'
export const LINK_URL_CONSULTANT_PLACEMENT_DETAIL = (id: number | string | string[]): string => `/consultants/placement/${id}`
export const LINK_URL_CONSULTANT_PLACEMENT_EDIT = (id: number | string | string[]): string => `/consultants/placement/${id}/edit`
export const LINK_URL_CONSULTANT_PLACEMENT_CREATE = (offer_id: number | string | string[]): string =>
  `/consultants/placement/create?offer_id=${offer_id}`

export const LINK_URL_CONSULTANT_COMPANIES_DETAIL = (id: number | string | string[]): string => `/consultants/companies/${id}`
export const LINK_URL_CONSULTANT_CLIENT_UPDATE = (id: number | string | string[]) => `/consultants/clients/edit/${id}`
export const LINK_URL_CONSULTANT_COMPANIES_DETAIL_PLACEMENT = (id: number | string | string[]): string =>
  `/consultants/companies/${id}?tab=placement`

export const LINK_URL_CONSULTANT_TASK = '/consultants/tasks'
export const LINK_URL_PUBLIC_JOB_DETAIL = (slug: string, id: number | string) =>
  `${NEXT_PUBLIC_APP_DOMAIN_SHARE}${LINK_URL_JOBS}/${slug}-i${id}`
export const LINK_URL_CONSULTANT_INVITE_MEMBER = `/account/invite-team-members`
export const LINK_URL_ON_LINE_CV_TEMPLATE = (slug: string) => `${NEXT_PUBLIC_APP_DOMAIN_SHARE}/api/profile-cv/${slug}`
export const LINK_URL_CONSUlTANT_CANDIDATE_DETAIL = (id: number | string | string[], jobID?: number | string): string =>
  `/consultants/candidates/${id}${jobID ? `?jobID=${jobID}` : ``}`
export const LINK_URL_CHAT_WITH_SUPPORT = `${process.env.NEXT_PUBLIC_APP_DOMAIN}/chat-with-support`

export const LINK_URL_CONSULTANT_JOB_TITLES = '/consultants/job-titles'
